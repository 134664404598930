.lab-details-table{
    border: 1px solid rgba(224, 224, 224, 1);
    background-color: white;
}
.inner-text-size{
   font-size: 12px;
}

.MuiTableCell-root {
    padding: 8px 15px !important;
  }