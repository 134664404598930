div.custom-container {
    background-color: #242424;
}

svg.custom .node circle {
    fill: #f3f3ff;
    stroke: #2593b8;
    stroke-width: 1.5px;
}

svg.custom .node text {
    font-size: 15px;
    background-color: #444;
    fill: black;
    /* text-shadow: 0 1px 4px black; */
}

svg.custom .node {
    cursor: pointer;
}

svg.custom path.link {
    fill: none;
    stroke: #2593b8;
    stroke-width: 1.5px;
    width: 100px;
}

.tree-div {
    background: var(--background-white) !important;
    margin: 1rem;
    border-radius: 20px !important;
    width: 97% !important;
    height: auto;
    padding: 10px;
    text-align: center;
}