

fieldset{
  color:#000 !important;
}

fieldset .component {
  width: 100%
}

.getallfiles .control-label {
  display: none
}

fieldset .accredited {
  align-items: flex-end;
}



.MuiTypography-h6 {
  font-family: outfit !important;
}




.MuiFormControlLabel-root .MuiFormControlLabel-label{
  font-size: 13px;
  /* font-weight: bold; */
  color: #384048  !important;

}


.formTitle{
  display: flex;
  height:35px;
  background: var(--primary-blue);
width:100%;
  color: #fff;
  padding:8px 24px;

}
.formBox-text{
 
  
  /* font-weight: 600  !important; */
  font-size: 14px   !important;
  line-height: 18px !important;
  font-family: outfit !important;


}
.formBox-helperText{
  margin-left: 20px !important;
  font-size: 12px   !important;
  line-height: 18px !important;
  
}

.MuiFormGroup-root{
  display: flex !important;
  flex-direction: row !important;
  color: #000;
  gap:20px;
  margin-left: 4px;

}
.MuiFormGroup-root span {
  /* border:1px solid red; */
  padding:0px;
 

  
}


.radio-button{
  margin-top: -10px;
  margin-left: 16px;
}





.field-radio-group {
  display: flex;
  gap: 10px
}

.MuiInputLabel-root {
  font-size: 12px !important;
}

.MuiInput-root {
  height: 25px !important;
}
.sourceCategory{
  margin: 16px 24px;
  padding:20 0px;

}



/* input{
  border-color: red;
} */
.MuiOutlinedInput-notchedOutline {
  border-color: #343A40 !important;

}

.table-head{
font-weight: 500 !important;
font-size: 12px !important;
line-height: 14px !important;
letter-spacing: 0.26px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input{
  font-size: 10px !important;
  line-height: 24px !important;
  color: #384048  !important;
}

.MuiInputBase-input .MuiOutlinedInput-input{
  font-size: 10px !important;
  line-height: 24px !important;
  color: #384048  !important;
}
.MuiFormLabel-root{
  font-size: 13px !important;
}

.MuiInputLabel-shrink {
  font-size: 16px !important;
}

.form-group{
  margin-bottom: 0px;
}

#VendorDetails > div{
  padding: 6px 24px !important;
}


.form-table td {
  height: 20px !important;
}
.form-table tr td {
  height: auto !important;
}
.form-table .MuiTableCell-root{
  padding:6px;
}

.form-table  .MuiTableCell-root{
  padding:6px;

}



.MuiFormLabel-root .MuiInputLabel-root.Mui-disabled{
  color:rgba(0,0,0,1) !important;
  /* font-weight: bold; */
}
.MuiFormControl-root .MuiInputBase-root .Mui-disabled{
  color:rgba(0,0,0,1) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
  
}