#batch-detail-form .save-button-div-disabled {
    display: none;
}

#batch-detail-form .save-button-div {
    position: absolute;
    bottom: 26px;
    margin: 0 40%;
}

.batch-form-styling .MuiGrid-root>.MuiGrid-item {
    flex-basis: calc(33% - 20px);
    align-items: flex-end;
    flex-wrap: wrap;
  }

.custom-date-field .MuiFormControl-root { 
    width: 100%;
}

.tool-tip p{
    visibility: hidden;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 1;
    background-color: rgba(97, 97, 97, 0.92);
    border-radius: 4px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    padding: 4px 8px;
    font-size: 0.6875rem;
    font-weight: 500;
    margin-top: 55px;
    margin-left: 5px;
}

.tool-tip:hover p{
    visibility: visible ;   
}
