.vendor-detail-styling .MuiGrid-root>.MuiGrid-item {
    flex-basis: calc(33% - 20px);
    align-items: flex-end;
    flex-wrap: wrap;
  }

.custom-radio-button .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    font-size: 10px;
  }

.custom-radio-button .MuiFormGroup-root {
  flex-flow: row wrap;
  row-gap: normal;
}

.custom-radio-button .MuiFormGroup-root .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #384048 !important;
  font-size: 12px;
}
.tool-tip p{
  visibility: hidden;
  color: #fff;
  text-align: left;
  position: absolute;
  z-index: 1;
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  padding: 4px 8px;
  font-size: 0.6875rem;
  font-weight: 500;
  margin-top: 55px;
  margin-left: 5px;
}

.tool-tip:hover p{
  visibility: visible ;   
}
