.test-type-form .MuiGrid-root>.MuiGrid-item {
  flex-basis: calc(33% - 20px);
  align-items: flex-end;
  flex-wrap: wrap;
}

.MuiInputBase-input .MuiOutlinedInput-input {
  font-size: 10px !important;
  line-height: 13px !important;
  color: #384048 !important;
}

input{
  /* line-height: 13px !important; */
  height: 2.4em !important;
}