#heading1{
    color:var(--primary-blue);
    font-size: 1rem;
    
}
#heading2{
    color:var(--primary-blue);
    font-size: 0.75rem !important;
    font-weight: 400 !important;

}

#heading1 .MuiTreeItem-content .MuiTreeItem-label{
    padding:5px 0px;
    text-align: start;
    font-weight: bolder !important;

}
#heading2 .MuiTreeItem-content .MuiTreeItem-label{
    padding:5px 0px;
    text-align: start;
    font-weight: bold !important;
    font-size: 0.75rem;

}
#heading1 .customClass .MuiTreeItem-label{
    color:var(--primary-blue) !important;
}
#heading2 .customClass .MuiTreeItem-label{
    color:black !important;
}


