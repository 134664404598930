.lab-details-table {
    background: var(--background-white) !important;
    margin-left: 1rem;
    border-radius: 10px !important;
    width: 97% !important;
}

.lab-details-table .MuiTableCell-body {
    font-size: 12px !important;
}
.lab-details-table .MuiTableCell-head{
    font-weight: 700 !important;
    font-size: 12px !important;
}
